<template>
    <b-card no-body>
        <b-card-header class="pb-50">
            <h5>
                {{ $t('label_filters') }}
            </h5>
        </b-card-header>
        <b-card-body>
            <b-row>

                <b-col
                        cols="12"
                        md="3"
                        class=" mb-1 date-range-col"
                >
                    <label>{{ $t('label_sort_by_agreement_conclusion') }}</label>

                    <div class="date-range-filter">
                        <custom-date-picker
                                class="date--picker no-label"
                                :locale="$store.state.appConfig.locale"
                                :placeholder="$t('label_DD-MM-YYYY')"
                                :value="dateFromFilter"
                                @input="(val) => {$emit('update:dateFromFilter', val)}"
                        >
                            <template #clear-btn="{ vm }">
                                <feather-icon icon="XIcon" size="14"/>
                            </template>
                        </custom-date-picker>

                        <div class="date-separator"/>

                        <custom-date-picker
                                class="date--picker no-label"
                                :locale="$store.state.appConfig.locale"
                                :placeholder="$t('label_DD-MM-YYYY')"
                                :value="dateToFilter"
                                @input="(val) => {$emit('update:dateToFilter', val)}"
                        >
                            <template #clear-btn="{ vm }">
                                <feather-icon icon="XIcon" size="14"/>
                            </template>
                        </custom-date-picker>
                    </div>

                </b-col>
                <b-col
                        v-if="$router.currentRoute.path != '/contacts-new' && $router.currentRoute.path != '/contacts-in-progress'"
                        cols="12"
                        md="3"
                        class="mb-md-0 mb-2"
                >
                    <label>{{ $t('status') }}</label>
                    <v-select
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :value="statusFilter"
                            :options="statusOptions"
                            class="w-100"
                            :placeholder="$t('label_select')"
                            :reduce="val => val.id"
                            @input="(val) => $emit('update:statusFilter', val)"
                    >
                        <template v-slot:option="option">

                            <span class="">{{ $t(option.translation_index) }}</span>
                        </template>
                        <template v-slot:selected-option="option">

                            <span class="">{{ $t(option.translation_index) }}</span>
                        </template>
                        <template v-slot:no-options="option">
                            {{$t('label_no_items')}}
                        </template>
                    </v-select>
                </b-col>
                <b-col
                        cols="12"
                        md="3"
                        class="mb-md-0 mb-2"
                >
                    <label>{{ $t('label_consultant') }}</label>
                    <infinity-scroll

                            selected-prop="id"
                            url="/consultant"
                            :placeholder="$t('label_select')"
                            @input="(val) => $emit('update:consultantFilter', val)"
                    >
                        <template #label="{item}">{{ item.name }}</template>

                    </infinity-scroll>

                </b-col>
                <b-col
                        cols="12"
                        md="3"
                        class="mb-md-0 mb-2"
                >
                    <label>{{ $t('label_recommended') }}</label>
                    <infinity-scroll

                            selected-prop="id"
                            url="/agent"
                            :placeholder="$t('label_select')"
                            @input="(val) => $emit('update:agentFilter', val)"
                    >
                        <template #label="{item}">{{ item.name }}</template>

                    </infinity-scroll>

                </b-col>
                <b-col
                        cols="12"
                        md="3"
                        class="mb-md-0 mb-2"
                >
                    <label>{{ $t('label_user') }}</label>
                    <infinity-scroll

                            selected-prop="id"
                            url="/user"
                            :placeholder="$t('label_select')"
                            @input="(val) => $emit('update:userFilter', val)"
                    >
                        <template #label="{item}">{{ item.name }}</template>

                    </infinity-scroll>

                </b-col>
                <b-col
                        cols="12"
                        md="3"
                        class="mb-md-0 mb-2 align-items-center d-flex"
                >
                    <!--<label  class="custom-control-label">-->

                        <b-form-checkbox
                                id="ref-link-filter"
                                :checked="refLinkFilter"
                                value="true"
                                @change="(val) => {$emit('update:refLinkFilter', val=='true'?'true' : null)}"
                        >
                            <label for="ref-link-filter">{{ $t('label_marketing') }}</label>
                        </b-form-checkbox>
                    <!--</label>-->


                </b-col>

            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>

    import {
        BCard, BCardHeader, BCardBody, BRow, BCol, BFormCheckbox,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    import infinityScroll from '@/views/components/infinityScroll'

    export default {
        components: {
            BRow,
            BCol,
            BCard,
            BCardHeader,
            BCardBody,
            BFormCheckbox,

            vSelect,

            infinityScroll
        },

        props: {

            statusFilter: {
                type: [String, null],
                default: null,
            },
            dateFromFilter: {
                type: [String, null],
                default: null,
            },
            dateToFilter: {
                type: [String, null],
                default: null,
            },

            statusOptions: {
                type: Array,
                required: true,
            },

            consultantFilter: {
                type: [String, null],
                default: null,
            },
            refLinkFilter: {
                type: [String, null],
                default: null,
            },
            agentFilter: {
                type: [String, null],
                default: null,
            },
            userFilter: {
                type: [String, null],
                default: null,
            },

        },

    }
</script>
