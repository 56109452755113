<template>

  <div>

    <div class="breadcrumb justify-content-end">
      <div class="d-flex  breadcrumb-wrapper pr-1">
        <b-breadcrumb-item :to="{ name: 'dashboard' }"> <feather-icon    class="align-text-top" icon="HomeIcon" size="15"/></b-breadcrumb-item>
        <b-breadcrumb-item  active>{{$t(curPageLabel)}}</b-breadcrumb-item>
      </div>
    </div>


    <table-filters
      :status-filter.sync="filterData.statusFilter"
      :date-from-filter.sync="filterData.dateFromFilter"
      :date-to-filter.sync="filterData.dateToFilter"
      :consultant-filter.sync="filterData.consultantFilter"
      :agent-filter.sync="filterData.agentFilter"
      :user-filter.sync="filterData.userFilter"
      :ref-link-filter.sync="filterData.refLinkFilter"
      :status-options="contactStatuses"
    />

     <table-data
             :ref="table_name"
             :filter-data="filterData"
             @addItem="isSidebarOpen = true"
             @editItem="isSidebarOpen = true"
     />

  </div>
</template>

<script>

    import tableFilters from './includes/tableFilters.vue'

    import tableData from './includes/tablelData'


    export default {
        components: {
            tableFilters,
            tableData,
        },
        data() {
            return {


                table_name: 'table-contacts',

                filterData: {
                    statusFilter: null,
                    dateFromFilter: null,
                    dateToFilter: null,
                    consultantFilter: null,
                    agentFilter: null,
                    userFilter: null,
                    refLinkFilter: null,
                },
                curPageLabel: this.$t('label_contacts'),
                contactStatuses: []

            }
        },

        watch: {
            $route(to, from) {
              this.resolveWhatDataShow(to.path);
            }
        },

        methods: {

            resolveWhatDataShow(route_path){

                switch (route_path) {
                    case '/contacts-new':
                        this.filterData.statusFilter = '108';
                        this.curPageLabel = 'label_new_clients';
                        break;
                    case '/contacts-in-progress':
                        this.filterData.statusFilter = '109';
                        this.curPageLabel = 'label_clients_in_progress';
                        break;
                    default:
                        this.curPageLabel = 'label_potential_clients';
                        this.filterData.statusFilter = null;
                }

            },
            refreshDataTable(){
                this.$refs[this.table_name].refreshDataTable();
            }

        },

        created() {
            this.async('get', '/select_options', {params:{options:['contact_statuses']}}, function(res){

                this.contactStatuses = res.data.contact_statuses;
            });
            this.resolveWhatDataShow(this.$router.currentRoute.path);

        },
    }
</script>

