var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"pb-50"},[_c('h5',[_vm._v(" "+_vm._s(_vm.$t('label_filters'))+" ")])]),_c('b-card-body',[_c('b-row',[_c('b-col',{staticClass:" mb-1 date-range-col",attrs:{"cols":"12","md":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t('label_sort_by_agreement_conclusion')))]),_c('div',{staticClass:"date-range-filter"},[_c('custom-date-picker',{staticClass:"date--picker no-label",attrs:{"locale":_vm.$store.state.appConfig.locale,"placeholder":_vm.$t('label_DD-MM-YYYY'),"value":_vm.dateFromFilter},on:{"input":function (val) {_vm.$emit('update:dateFromFilter', val)}},scopedSlots:_vm._u([{key:"clear-btn",fn:function(ref){
var vm = ref.vm;
return [_c('feather-icon',{attrs:{"icon":"XIcon","size":"14"}})]}}])}),_c('div',{staticClass:"date-separator"}),_c('custom-date-picker',{staticClass:"date--picker no-label",attrs:{"locale":_vm.$store.state.appConfig.locale,"placeholder":_vm.$t('label_DD-MM-YYYY'),"value":_vm.dateToFilter},on:{"input":function (val) {_vm.$emit('update:dateToFilter', val)}},scopedSlots:_vm._u([{key:"clear-btn",fn:function(ref){
var vm = ref.vm;
return [_c('feather-icon',{attrs:{"icon":"XIcon","size":"14"}})]}}])})],1)]),(_vm.$router.currentRoute.path != '/contacts-new' && _vm.$router.currentRoute.path != '/contacts-in-progress')?_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t('status')))]),_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"value":_vm.statusFilter,"options":_vm.statusOptions,"placeholder":_vm.$t('label_select'),"reduce":function (val) { return val.id; }},on:{"input":function (val) { return _vm.$emit('update:statusFilter', val); }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('span',{},[_vm._v(_vm._s(_vm.$t(option.translation_index)))])]}},{key:"selected-option",fn:function(option){return [_c('span',{},[_vm._v(_vm._s(_vm.$t(option.translation_index)))])]}},{key:"no-options",fn:function(option){return [_vm._v(" "+_vm._s(_vm.$t('label_no_items'))+" ")]}}],null,false,3619629559)})],1):_vm._e(),_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t('label_consultant')))]),_c('infinity-scroll',{attrs:{"selected-prop":"id","url":"/consultant","placeholder":_vm.$t('label_select')},on:{"input":function (val) { return _vm.$emit('update:consultantFilter', val); }},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.name))]}}])})],1),_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t('label_recommended')))]),_c('infinity-scroll',{attrs:{"selected-prop":"id","url":"/agent","placeholder":_vm.$t('label_select')},on:{"input":function (val) { return _vm.$emit('update:agentFilter', val); }},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.name))]}}])})],1),_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t('label_user')))]),_c('infinity-scroll',{attrs:{"selected-prop":"id","url":"/user","placeholder":_vm.$t('label_select')},on:{"input":function (val) { return _vm.$emit('update:userFilter', val); }},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.name))]}}])})],1),_c('b-col',{staticClass:"mb-md-0 mb-2 align-items-center d-flex",attrs:{"cols":"12","md":"3"}},[_c('b-form-checkbox',{attrs:{"id":"ref-link-filter","checked":_vm.refLinkFilter,"value":"true"},on:{"change":function (val) {_vm.$emit('update:refLinkFilter', val=='true'?'true' : null)}}},[_c('label',{attrs:{"for":"ref-link-filter"}},[_vm._v(_vm._s(_vm.$t('label_marketing')))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }